import { css } from 'styled-components';
import theme from 'styles/theme';

const media = Object.keys(theme.main.sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${theme.main.sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
