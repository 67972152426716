/* Colors */
const colors = {
  white: '#fff',
  lightGreen: '#5bcb9d',
  green: '#00ac58',
  greenRebrand: '#02A97F',
  blue: '#0081de',
  blueFocus: '#25bfe7',
  black: '#000',
  lightestGrey: '#e6e6e6',
  lightGrey: '#c8c8c8',
  grey: '#8c8c8c',
  darkGrey: '#4c4c4c',
  red: '#da3333',
  yellow: '#fbae39',
  orange: '#f39100',
  transparent: 'transparent',
  facebook: '#27529c',
  instagram: '#ff016b',
  twitter: '#47abd9',
  youtube: '#e1453e',
  linkedin: '#007ab9',
};

/* Fonts */
const fonts = {
  family: 'Open Sans, sans-serif',
  size: '14px',
  lineHeight: '1.5',
  weight: {
    light: 300,
    normal: 400,
    bold: 600,
    bolder: 700,
  },
};

export const sizes = {
  largeDesktop: 1280,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

const zIndex = {
  modal: 10,
  overlay: 20,
  tooltip: 30,
  header: 40,
  mainContent: 50,
};

const ease = {
  linear: 'cubic-bezier(0.25, 0.25, 0.75, 0.75)',
  ease: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  easeIn: 'cubic-bezier(0.42, 0, 1, 1)',
  easeInQuad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
  easeInCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  easeInQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
  easeInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
  easeInExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  easeInCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
  easeOut: 'cubic-bezier(0, 0, 0.58, 1)',
  easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  easeOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  easeInOut: 'cubic-bezier(0.42, 0, 0.58, 1)',
  easeInOutQuad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeInOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
  easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  easeInOutExpo: 'cubic-bezier(1, 0, 0, 1)',
  easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
};

const gradients = {
  slides: {
    first: `linear-gradient(to left, #00ac58, #5bcb9d)`,
    second: `linear-gradient(to left, #660eaf, #8116da)`,
    third: `linear-gradient(to left, #0b486b, #246b94)`,
  },
  featuredVacancies: `linear-gradient(to top, rgba(230, 230, 230, 0), #e6e6e6)`,
  comunity: `linear-gradient(280deg, #2ea7da, #2ec7da)`,
};

const theme = {
  main: {
    /* Colors */
    colors: {
      ...colors,
      // Scaffolding
      primary: colors.blue,
      secondary: colors.green,
      success: colors.green,
      error: colors.red,
      danger: colors.yellow,
      institutional: colors.blue,
      candidates: colors.green,
      forbusiness: colors.orange,
    },

    /* Fonts */
    fonts: { ...fonts },

    /* Gradients */
    gradients: { ...gradients },

    /* Devices */
    sizes: { ...sizes },

    /* Z-Index */
    zIndex: { ...zIndex },

    easing: {
      ...ease,
    },
  },
};

export default theme;
