import styled, { css } from 'styled-components';
import { media } from 'styles';
import * as T from 'styles/typography';
import SlideAMobile from 'images/background_A_mobile.webp';
import SlideAMobile_jpg from 'images/background_A_mobile.jpg';
import SlideA from 'images/background_A_desktop.webp';
import SlideA_jpg from 'images/background_A_desktop.jpg';

/* SECTION STYLES */
export const StyledSection = styled.section`
  background-color: transparent;
  padding: 24px 0;

  ${props =>
    props.isGrey &&
    css`
      background-image: ${({ theme }) =>
        theme.main.gradients.featuredVacancies};
    `}

  a[type="primary"] {
    margin: 25px auto 0;
    max-width: 300px;
    width: 100%;
  }
`;

export const SectionTitle = styled(T.H2)`
  color: ${({ theme }) => theme.main.colors.darkGrey};
  text-align: center;
`;

export const SectionParagraph = styled(T.Paragraph)`
  color: ${({ theme }) => theme.main.colors.darkGrey};
  text-align: center;
`;

/* SECTIONS CUSTOM STYLES */
export const HeroSection = styled(StyledSection)`
  padding: 0;

  h1 {
    color: ${({ theme }) => theme.main.colors.white};
    font-size: 24px;
    font-weight: 400;
    padding: 0 16px;
    text-align: center;

    ${media.desktop`
      font-size: 32px;
      padding: 0;
    `}
  }

  h2 {
    color: ${({ theme }) => theme.main.colors.white};
    font-size: 15px;
    line-height: 1.5em;
    margin-bottom: 32px;
    margin-top: 0;
    padding: 0 16px;
    text-align: center;

    ${media.desktop`
      font-size: 16px;
    `}
  }
`;

export const HeroContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.main.colors.greenRebrand};
  display: flex !important;
  flex-direction: column;
  height: 440px;
  ${media.desktop`
    height: 400px;
  `}
  justify-content: center;
  padding-top: 72px;
  width: 100%;

  > a {
    z-index: 1;
  }
`;

export const VacancyCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    justify-content: space-between;
  `}

  ${media.desktop`
    justify-content: space-between;
  `}
`;

export const CompaniesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const CompaniesLogoWrapper = styled.a`
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 16px 8px;
  width: 49%;

  img {
    max-height: 60px;
    max-width: 180px;
  }

  [data-logo-name='Accenture'] {
    max-height: 70px;
  }

  [data-logo-name='Grupo Siti'] {
    max-height: 50px;
  }

  [data-logo-name='Robert Half'] {
    max-height: 160px;
  }

  [data-logo-name='Stone'] {
    max-height: 55px;
  }

  [data-logo-name='Totvs'] {
    max-height: 85px;
  }

  ${media.tablet`
    width: 49%;
  `}

  ${media.desktop`
    width: 19%;
  `}
`;

export const ComunitySection = styled(StyledSection)`
  background-image: ${({ theme }) => theme.main.gradients.comunity};
  padding: 62px 0;

  h2,
  p {
    color: ${({ theme }) => theme.main.colors.white};
  }
`;

export const ComunityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ComunityBlock = styled.div`
  padding: 24px 0;
  text-align: center;
  width: 100%;

  .box-icon {
    color: white;
  }

  h3,
  p {
    color: ${({ theme }) => theme.main.colors.white};
  }

  h3 {
    font-size: 20px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  ${media.tablet`
    width: 48%;
  `}

  ${media.desktop`
    width: 24%;
  `}
`;

export const MobileAppsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    margin: 0 10px;

    &,
    img {
      max-width: 150px;
      width: 100%;
    }
  }
`;
