import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  *,
  *:before,
  *:after {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  html {
    font-family: ${({ theme }) => (theme.main.fonts.family)}, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: ${({ theme }) => (theme.main.fonts.size)};
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  /* Custom Styles for React Slick */
  .slick-dots {
    bottom: 15px !important;
  }

  .slick-dots li button:before {
    background-color: #eee !important;
    border-radius: 50%;
    color: transparent !important;
  }

  .slick-dots li.slick-active button:before {
    background-color: #5bcb9d !important;
  }

  /* Overrides */
  .vg-header__404-page {
    background-image: linear-gradient(to right, #5acb9d, #28ad5a) !important;
    position: relative !important;
  }

  .link-default {
    color: #0081de;
  }
`;

export default GlobalStyle;
