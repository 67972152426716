import styled from 'styled-components';
import { media } from 'styles';

export const H1 = styled.h1`
  font-size: 32px;
  font-weight: 400;
  line-height: 36px;
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;

  ${media.desktop`
    font-size: 32px;
  `}
`;

export const Paragraph = styled.p`
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 1.5em;
`;